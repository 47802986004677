import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '../../uikit-foodcourt'
import useI18n from 'hooks/useI18n'

const StyledNav = styled.div`
	margin-bottom: 40px;
`

function Nav({ activeIndex = 0 }: { activeIndex?: number }) {
	const TranslateString = useI18n()

	return (
		<StyledNav>
			<ButtonMenu activeIndex={activeIndex} scale="sm" variant="subtle">
				<ButtonMenuItem id="swap-nav-link" to="/swap" as={Link}>
					Exchange
				</ButtonMenuItem>
				<ButtonMenuItem id="pool-nav-link" to="/pool" as={Link}>
					Liquidity
				</ButtonMenuItem>
				{/* <ButtonMenuItem id="wrap-nav-link" to="/wrap" as={Link}>
					Wrap
				</ButtonMenuItem> */}
			</ButtonMenu>
		</StyledNav>
	)
}

export default Nav
